import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import Buttons from "../../components/button/button";

const HeaderWrapper = styled.header`
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  position: relative;
  z-index: 50;
  transition: 0.25s ease-in-out;
`;

export const HeaderTop = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  transition: 0.25s ease-in-out;
  width: 1170px;
  padding: 30px 15px;
  margin: 0 auto;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 20px;
  }
`;

export const ContactUs = styled.div`
  margin-right: 20px;
  button {
    width: 120px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: ${themeGet("primary", "#D7AB92")};
    &:hover {
      background-color: ${themeGet("black", "#292929")};
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      height: 42px;
      padding: 0 22px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Button = styled(Buttons)`
  border-radius: 3px;
  font-size: 14px;
`;

export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: 0.25s ease-in-out;
  width: 1170px;
  padding: 30px 15px;
  margin: 0 auto;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const Logo = styled.div`
  width: 170px;
  margin: auto;
  flex-shrink: 0;
 
  @media (max-width: 990px) {
    margin-left: 0;
    margin-right: auto;
  }
  @media (max-width: 575px) {
    max-width: 90px;
  }
  a {
    color: ${themeGet("colors.textColor", "#272727")};
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  @media (max-width: 990px) {
    display: none;
  }
`;

export const MenuItemWrapper = styled.ul`
  display: flex;
  align-items: center;
`;



export const MenuItem = styled.li`
  margin: 0 20px;
  list-style: none;
  a, span {
    padding: 5px 10px;
    white-space: nowrap;
    font-size: ${themeGet("fontSizes.3", "15")}px;
    line-height: ${themeGet("lineHeights.normal", "1")};
    font-weight: ${themeGet("fontWeights.3", "400")};
    color: red;
    transition: 0.15s ease-in-out;
  }
  a {
    color: ${themeGet("colors.textColor", "#272727")};
    &:hover {
      color: ${themeGet("colors.lightTextColor", "#757575")};
    }
    &.active-link {
      color: ${themeGet("colors.lightTextColor", "#757575")};
      font-weight: ${themeGet("fontWeights.6", "700")};
    }


  }
  
`;

export const Arrow = styled.i`
border: solid ${themeGet("colors.textColor", "#272727")};
border-width: 0 1px 1px 0;
margin: 0 0 3px 6px;
display: inline-block;
padding: 3px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);

`

export const SubMenuItem = styled(MenuItem)`
a {
  margin-left: 10px;
}
 
`;




export const CategoryItemWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  li:not(:last-child):after {
    margin-right: 5px;
    padding-left: 5px;
    content: "/";
  }
`;

export const CategoryItem = styled.li`
  list-style: none;
  margin-bottom: 5px;
  a {
    white-space: nowrap;
    font-size: ${themeGet("fontSizes.3", "15")}px;
    line-height: ${themeGet("lineHeights.normal", "1")};
    font-weight: ${themeGet("fontWeights.3", "400")};
    color: ${themeGet("colors.textColor", "#272727")};
    transition: 0.15s ease-in-out;

    &:hover {
      color: ${themeGet("colors.lightTextColor", "#757575")};
    }
    &.active-link {
      color: ${themeGet("colors.textColor", "#272727")};
      font-weight: ${themeGet("fontWeights.6", "700")};
    }
  }
`;

export const NavSearchButton = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
  padding: 5px 0 5px 10px;
  margin-right: 30px;
  cursor: pointer;
  svg {
    display: block;
  }
`;

export const SearchCloseButton = styled.button`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  cursor: pointer;
  z-index: 1;
  padding: 0;
  color: #757575;
  font-size: 45px;
  margin-left: -54px;
  @media (max-width: 990px) {
    font-size: 35px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
  }
  svg {
    display: block;
  }
`;

export const SocialProfileWrapper = styled.div`
  @media (max-width: 990px) {
    display: none;
  }
`;

export const NavSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  height: 0;
  visibility: hidden;
  transition: 0.35s ease-in-out;

  > * {
    opacity: 0;
    transition: 0.35s ease-in-out;
  }

  &.expand {
    height: 145px;
    visibility: visible;
    @media (max-width: 1199px) {
      height: 120px;
    }
    @media (max-width: 990px) {
      height: 100px;
    }
    @media (max-width: 767px) {
      height: 75px;
    }
    .nav-search-input,
    > * {
      opacity: 1;
    }
  }

  .nav-search-input {
    width: 870px;
    transition: 0.35s ease-in-out;

    .inner-wrap {
      .search-icon {
        width: 70px;
      }
      input,
      .icon-left {
        height: 54px;
        padding: 0 70px;
        border-radius: 3px;
        ::placeholder {
          color: #757575;
        }
      }
    }
  }
`;

export const NavSearchFromWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  @media (min-width: 990px) {
    width: 900px;
  }
  @media (min-width: 1200px) {
    width: 1050px;
  }
  @media (min-width: 1400px) {
    width: 1170px;
  }
  @media (max-width: 990px) {
    padding: 0 45px;
  }
  @media (max-width: 767px) {
    padding: 0 25px;
  }
`;

export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: "";
    width: 30px;
    height: 1px;
    background: #292929;
    display: block;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;

export const MobileMenuWrapper = styled.div`
  @media (min-width: 991px) {
    display: none;
  }
`;

export const DrawerContentWrapper = styled.div`
  padding: 30px;
  .mobile-menu {
    padding-top: 60px;
  }
`;

export const DrawerContentBlock = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DrawerContentTitle = styled.span`
  font-size: ${themeGet("fontSizes.4", "16")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.textColor", "#292929")};
  margin-bottom: 30px;
`;

export const DrawerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 0;
`;

export const DrawerClose = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${themeGet("colors.primaryLight", "#E6D0C3")};

  &:hover{
    background: ${themeGet("colors.primaryHover", "#D7AB92")};
  }
  font-size: 23px;
  color: ${themeGet("colors.white", "#ffffff")};
`;

export const HamburgerIcon = styled.div`
  width: 30px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  span {
    display: block;
    height: 2px;
    background-color: ${themeGet("colors.textColor", "#292929")};
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
      width: 20px;
    }
  }
`;

export const HamburgerIconSquare = styled.div`
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  span {
    display: block;
    height: 2px;
    background-color: ${themeGet("colors.textColor", "#292929")};
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
      width: 20px;
    }
  }
`;



export const MenuListWrapper = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet("colors.primaryLight", "#E6D0C3")};
  > ul {
    text-align: center;
    display: flex;
    width: 1170px;
    padding: 0 15px;
    @media (max-width: 1200px) {
      width: 100%;
    }
    
 
  }

  @media (max-width: 991px) {
    display: none;
  }



`;

export const MenuList = styled.ul`
  text-transform: uppercase;
  list-style: none;
  height:100%;
  justify-content: space-between;



`;

export const MenuListItem = styled.li`

  position: relative;
  display: inherit;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;
  &:nth-child(1) {
  
    text-align: left;
  }
  &:nth-last-child(1) {

    text-align: right;

  }
  &:hover {
   > ul {
     display:block;
    }
  }
  > a {
    cursor: pointer;
  }
  > a, > span {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    white-space: nowrap;
    font-size: ${themeGet("fontSizes.3", "15")}px;
    line-height: ${themeGet("lineHeights.normal", "1")};
    font-weight: ${themeGet("fontWeights.3", "400")};
    color: ${themeGet("colors.textColor", "#272727")};
    transition: 0.15s ease-in-out;
    padding: 0;

    &:hover {
     
      color: ${themeGet("colors.lightTextColor", "#757575")}
      
    }
    }
  
`;

export const SubMenuList = styled.ul`
  display:none;
  position: absolute;
  left: 0px; 
  top: 35px;
  text-transform: uppercase;
  list-style: none;
  text-align: left;
  z-index: 60;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);

`;
export const SubMenuListItem = styled.li`
display:block;
background-color: ${themeGet("colors.primaryLight", "#E6D0C3")};
padding: 0;
height: 35px;
width: 100%;


> a {
  display:flex;
  align-items: center;
  padding: 0px 8px;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: ${themeGet("fontSizes.3", "15")}px;
  line-height: ${themeGet("lineHeights.normal", "1")};
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.textColor", "#272727")};
  transition: 0.15s ease-in-out;


  &:hover {
    color: ${themeGet("colors.lightTextColor", "#757575")}
  }

}


`;

export default HeaderWrapper;
