import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { DrawerProvider } from "../../components/drawer/drawer-context";
import SocialProfile from "../../components/social-profile/social-profile";
import Drawer from "./drawer";
import usePageInformation from "../../hooks/use-page-information";

//import Button from '../../components/button/button';
import HeaderWrapper, {
  HeaderTop,
  TopBarWrapper,
  Logo,
  SocialProfileWrapper,
  MenuListWrapper,
  MenuList,
  MenuListItem,
  Arrow,
  SubMenuList,
  SubMenuListItem
} from "./navbar.style";
import LogoImage from "../../images/reenideco.svg";

import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoMdMail
} from "react-icons/io";

type NavbarProps = {
  className?: string;
};





const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const pageInfo:any = usePageInformation();
  const {email, instagram, facebook} = pageInfo;

  const SocialLinks = [
    {
      icon: <IoLogoFacebook />,
      url: facebook,
      tooltip: '',
    },
    {
      icon: <IoLogoInstagram />,
      url: instagram,
      tooltip: '',
    },
    {
      icon: <IoMdMail />,
      url: `mailto:${email}`,
      tooltip: '',
    }
  
  ];

  const [state, setState] = useState({
    toggle: false,
    search: "",
  });

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };

  // Add all classs to an array
  const addAllClasses = ["header"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const Data = useStaticQuery(graphql`
    query {
      menuItems:allContentfulMenuItem(sort: {order: ASC, fields: order}) {
        nodes {
          name
          slug
          pages {
            slug
            title
          }
        }
      }
    }
  `);



  const menuItems = Data.menuItems.nodes;

  return (
    <HeaderWrapper className={addAllClasses.join(" ")} {...props}>
      <HeaderTop>
        <TopBarWrapper>
          <SocialProfileWrapper>
            <SocialProfile items={SocialLinks} />
          </SocialProfileWrapper>
          <Logo>
            <Link to="/">
              <img src={LogoImage} alt="logo" />
            </Link>
          </Logo>
          <DrawerProvider>
            <Drawer menuItems={menuItems} logo={LogoImage} />
          </DrawerProvider>
        </TopBarWrapper>
      </HeaderTop>
      <MenuListWrapper>
        <MenuList>
        { menuItems.map((item: any, index: number) => { 
          return (
            <MenuListItem key={index + 1}>
              { item.pages && item.pages.length > 1 ? <span>{item.name}<Arrow/></span> : <Link to={`/${item.pages ?item.pages[0].slug : item.slug}`}>{item.name}</Link> } 
              {item.pages && item.pages.length > 1 && 
              <SubMenuList>
                { item.pages.map((item: any, index: number) => (
                <SubMenuListItem key={index + 1}><Link to={`/${item.slug}`}>{item.title}</Link></SubMenuListItem>
                ))}
              </SubMenuList> }
            </MenuListItem> 
          
          )})
        }
          
        </MenuList>
      </MenuListWrapper>
    </HeaderWrapper>
  );
};

export default Navbar;
