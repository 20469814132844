import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SocialProfile from '../../components/social-profile/social-profile';
import LogoImage from '../../images/RDLogo.svg';
import Menu from '../../components/menu';
import usePageInformation from "../../hooks/use-page-information";

import FooterWrapper, {
  FooterCol,
  Logo,
  Infos,
  FooterTitle,
  FooterContent,
} from './footer.style';


import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoMdMail
} from 'react-icons/io';

const MenuItems = [
  {
    label: 'About',
    url: '/about',
  },
  {
    label: 'Contact',
    url: '/contact',
  },
  {
    label: '404 Page',
    url: '/404',
  },
];



type FooterProps = {
  children: React.ReactNode;
};

const Footer: React.FunctionComponent<FooterProps> = ({
  children,
  ...props
}) => {

  const pageInfo:any = usePageInformation();
  const {footerMenuPages:pages, footerMenuServices:services, pageTitle, email, phoneNumber, instagram, facebook} = pageInfo;

  const SocialLinks = [
    {
      icon: <IoLogoFacebook />,
      url: facebook,
      tooltip: '',
    },
    {
      icon: <IoLogoInstagram />,
      url: instagram,
      tooltip: '',
    },
    {
      icon: <IoMdMail />,
      url: `mailto:${email}`,
      tooltip: '',
    }
  
  ];

 
  return (
    <FooterWrapper {...props}>
      <FooterCol>
        <Logo>
          <Link to="/">
            <img src={LogoImage} alt="logo" />
          </Link>
        </Logo>

        {/* <Infos className="heading"></Infos> */}
        <Infos className="text">{"Reeni Deco ir radošā aģentūra, kas piedāvā pasākumu dekorēšanu,reklāmas un telpu dizaina noformējumu, kā arī meistarklases un dekorāciju nomu."}</Infos>
        <Infos>
          &copy;&nbsp;
          {new Date().getFullYear()} Reeni Deco
        </Infos>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Reeni Deco</FooterTitle>
         <FooterContent>
         <Menu items={ pages } className="footer-menu" />
        </FooterContent> 
      </FooterCol>

       <FooterCol>
        <FooterTitle>Pakalpojumi</FooterTitle>
        <Menu items={ services } className="footer-menu" />
      {/*  <FooterContent>
            {Pages.map(({ node: page }, index) => (
            <Menu key={index} to={`/${page.slug}`}>
              {page.title}
            </Menu>
          ))}
        </FooterContent>  */}
      </FooterCol> 

      <FooterCol>
        <FooterTitle>Sazinies ar mums</FooterTitle>
        <Infos><a href={`mailto:${email}`}>{email}</a></Infos>
        <Infos>{phoneNumber}</Infos>
        <SocialProfile items={SocialLinks} />
      </FooterCol>
    </FooterWrapper>
  );
};

export default Footer;
