import { useStaticQuery, graphql } from 'gatsby'

const usePageInformation = () => {
 const { contentfulPageInformation } = useStaticQuery(usePageInformationQuery)
 return contentfulPageInformation
}

const usePageInformationQuery = graphql`
    query PageInformationQuery {
        contentfulPageInformation {
            defaultSocialShareImage {
              gatsbyImageData(width: 1000, height: 800)
            }
            description
            email
            facebook
            footerMenuPages {
              name
              slug
              pages {
                title
                slug
              }
            }
            footerMenuServices {
              name
              slug
              pages {
                title
                slug
              }
            }
            instagram
            phoneNumber
          }
    }
`

export default usePageInformation