import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const MenuItemWrapper = styled.ul`
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.li`
  margin: 0 20px;
  list-style: none;
  a,
  span {
 
    white-space: nowrap;
    font-size: ${themeGet("fontSizes.3", "15")}px;
    line-height: ${themeGet("lineHeights.normal", "1")};
    font-weight: ${themeGet("fontWeights.4", "500")};
    transition: 0.15s ease-in-out;
  }
  a {
    color: ${themeGet("colors.lightTextColor", "#757575")};
    &:hover {
      color: ${themeGet("colors.textColor", "#292929")};
    }
    &.active-link {
      color: ${themeGet("colors.textColor", "#292929")};
      font-weight: ${themeGet("fontWeights.6", "700")};
    }
  }
`;

export const SubMenuItem = styled(MenuItem)`

  a {
  
 
  }
`;
