import React, { useContext } from 'react';
import Drawer from '../../components/drawer/drawer';
import SocialProfile from '../../components/social-profile/social-profile';
import { DrawerContext } from '../../components/drawer/drawer-context';
import Menu from '../../components/menu-sidebar';


import {
  DrawerContentWrapper,
  DrawerHead,
  DrawerClose,
  DrawerContentBlock,
  DrawerContentTitle,
  HamburgerIconSquare,
} from './navbar.style';
import { FiX } from 'react-icons/fi';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoMdMail
} from 'react-icons/io';

type MobileMenuProps = {
  pages?: any;
  categories?: any;
  logo: string;
};

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/reenideco/',
    tooltip: '',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/reenideco/',
    tooltip: '',
  },
  {
    icon: <IoMdMail />,
    url: 'mailto:reenideco@gmail.com',
    tooltip: '',
  }
  /* {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/redqinc',
    tooltip: '',
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/company/redqinc/',
    tooltip: '',
  }, */
];

const DrawerComp: React.FunctionComponent<MobileMenuProps> = ({ menuItems }) => {
  const { state, dispatch }: any = useContext(DrawerContext);

  // Toggle drawer
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <Drawer
      width="375px"
      placement="right"
      drawerHandler={
        <HamburgerIconSquare>
          <span />
          <span />
          <span />
        </HamburgerIconSquare>
      }
      open={state.isOpen}
      toggleHandler={toggleDrawer}
    >
      <DrawerContentWrapper>
        <DrawerHead>
          <DrawerClose onClick={toggleDrawer}>
            <FiX />
          </DrawerClose>
        </DrawerHead>
        { menuItems && <Menu items={ menuItems } className="mobile-menu" /> }
         <DrawerContentBlock>

  
         {/*  <DrawerContentTitle>Instagram</DrawerContentTitle>
          <InstaDrawerGrid /> */}
        </DrawerContentBlock> 

        <DrawerContentBlock>
          <DrawerContentTitle>Seko mums</DrawerContentTitle>
          <SocialProfile items={SocialLinks} />
        </DrawerContentBlock>
      </DrawerContentWrapper>
    </Drawer>
  );
};

export default DrawerComp;
