import React from "react";
import { Link } from "gatsby";
import { MenuItemWrapper, MenuItem,SubMenuItemWrapper, SubMenuItem, Arrow } from "./menu.style";

type MenuProps = {
  items: MenuItemsProps[];
  className?: string;
};

type MenuItemsProps = {
  slug: string;
  title?: string;
  name?: string;
  pages?: any[]
  external?: boolean;
};

const Menu: React.FunctionComponent<MenuProps> = ({
  items,
  className,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ["menu"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <MenuItemWrapper className={addAllClasses.join(" ")} {...props}>
      {items.map((item, index) => (
        <MenuItem key={index + 1}>
           { item.pages && item.pages.length > 1 ? <a  href="#" tabIndex={0}>{item.name}<Arrow/></a> : <Link tabIndex={0} to={`/${item.pages ?item.pages[0].slug : item.slug}`}>{item.name}</Link> }
             {item.pages && item.pages.length > 1 &&  <SubMenuItemWrapper>
             { item.pages.map((item: any, index: number) => (
             <SubMenuItem key={index + 1}><Link tabIndex={0} to={`/${item.slug}`}>{item.title}</Link></SubMenuItem>
             ))}
             </SubMenuItemWrapper>
             } 
        </MenuItem>
      ))}
    </MenuItemWrapper>
  );
};

export default Menu;
