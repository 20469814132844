const colors = {
  transparent: 'transparent', // 0
  black: '#292929', // 1
  white: '#ffffff', // 2
  body: '#F5F5F5', 
  textColor: '#272727', // 3
  lightTextColor: '#757575', // 4
  inactiveField: '#F7F7F7', // 5
  inactiveColor: '#767676', // 6
  inactiveBG: '#e6e6e6', // 7
  borderColor: '#DBDBDB', // 8
  lightBorderColor: '#ededed', //9
  primary: '#CFB8A8', // 10
  primaryLight: '#E6D0C3', // 10
  primaryHover: '#D7AB92', // 11
  textLightColor: '#5e5e5e', // 3
  textLinkColor: '#909090', // 3b
};

export default colors;
