import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const MenuItemWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
`;

export const MenuItem = styled.li`
  overflow: hidden;
  width: 100%;
  margin: 5px 0 5px 0;
  list-style: none;
  font-family: "Playfair Display", sans-serif;
  &:focus-within {
    > a {
      color: ${themeGet("colors.primaryHover", "#D7AB92")};
      &:hover {
        color: ${themeGet("colors.primaryHover", "#D7AB92")};
      }
      i {
        border-color: ${themeGet("colors.primaryHover", "#D7AB92")};
      }
    }

    ul {
      max-height: 40rem;
    }
  }
  a,
  span {
    display: block;
    position: relative;
    text-align: center;
    white-space: nowrap;
    font-size: 1.3rem;
    line-height: ${themeGet("lineHeights.normal", "1")};
    font-weight: ${themeGet("fontWeights.4", "500")};
  }
  a {
    color: ${themeGet("colors.lightTextColor", "#757575")};


    &:hover {
      color: ${themeGet("colors.textColor", "#292929")};
    }
  }
`;

export const SubMenuItemWrapper = styled(MenuItemWrapper)`
  max-height: 0;
  margin-top: 5px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
`;

export const SubMenuItem = styled(MenuItem)`
  a {
    font-weight: ${themeGet("fontWeights.3", "400")};
  }
`;

export const Arrow = styled.i`
  border: solid ${themeGet("colors.lightTextColor", "#757575")};
  border-width: 0 2px 2px 0;
  margin: 0 0 4px 4px;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;
